@import "@patternfly/patternfly/sass-utilities/all"; // PF CORE
@import "@patternfly/pfe-sass/variables/_4-sizes";   // PFE Sass vars
@import "utilities";
@import "print-maps";

/* THIS FILE HAS GLOBAL VARIABLES SET IN REM UNITS */

:root {
  
  // MAP file: general
  @each $property, $result in $spacing {
    @each $level, $value in $result {
         --pfe-theme--#{$property}-#{$level}:  #{pf-font-prem($value)};
    }
  } 
  // MAP file: typography
  @each $property, $result in $global {
    @each $level, $value in $result {
         --pf-global--#{$property}--#{$level}: #{pf-font-prem($value)};
    }
  } 

  // Set Content block + title & text modifier variables
  // MAP file: typography  
  @each $property, $result in $size {
      @if $property == "title--m" or $property == "text--m" {
        @each $level, $value in $result {
          --pf-c-#{$property}-#{$level}: #{pf-font-prem($value)};
        } 
      } @else {
        @each $level, $value in $result {
          --pf-c-#{$property}--#{$level}: #{pf-font-prem($value)};
        }
      }
  }
  @media screen and (max-width: 767px) {
    @each $property, $result in $size-mobile {
      @if $property == "title--m" or $property == "text--m" {
        @each $level, $value in $result {
          --pf-c-#{$property}-#{$level}: #{pf-font-prem($value)};
        } 
      } @else {
        @each $level, $value in $result {
          --pf-c-#{$property}--#{$level}: #{pf-font-prem($value)};
        }
      }
    }
  }

  /*  DEPRECATED */
  //MAP file: typography-deprecated
  @each $property, $result in $font-desktop-deprecated {
    @each $level, $value in $result {
      @if $level == "default" {
        --#{$prefix}-theme--#{$property}: #{$value};
      } @else {
        --#{$prefix}-theme--#{$property}--#{$level}: #{pf-font-prem($value)};
      }
    }
  }

 // MAP file: typography-deprecated 
  @media screen and (max-width: 767px) {
    @each $property, $result in $font-mobile-deprecated {
      @each $level, $value in $result {
        @if $level == "default" {
          --#{$prefix}-theme--#{$property}: #{$value};
        } @else {
          --#{$prefix}-theme--#{$property}--#{$level}: #{pf-font-prem($value)};
        }
      }
    }
  } 

  @include print-vars-in-maps;
};